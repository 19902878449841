import styles from '@styles/components/ModalSignInSignUp/ModalSignInSignUp.module.scss';
import HorizontalBar from '@components/HorizontalBar';

type Props = {
  isSignIn?: boolean,
  setIsSignIn?: boolean,
};

export default function Switch({ isSignIn, setIsSignIn }: Props) {
  return (
    <div className={styles.switchContainer}>
      <div
        className="flex flex-col h-full relative justify-center w-80px"
        style={{ marginRight: 40 }}>
        <button
          onClick={() => setIsSignIn(true)}
          className={`${styles.titleSwitch} ${isSignIn && styles.titleActive}`}>
          Đăng nhập
        </button>
        <HorizontalBar open={isSignIn} className={styles.horizontalBar} />
      </div>
      <div className="flex flex-col h-full relative justify-center w-80px">
        <button
          onClick={() => setIsSignIn(false)}
          className={`${styles.titleSwitch} ${
            !isSignIn && styles.titleActive
          }`}>
          Đăng ký
        </button>
        <HorizontalBar open={!isSignIn} className={styles.horizontalBar} />
      </div>
    </div>
  );
}

import React from 'react';
import dynamic from 'next/dynamic';
import styles from '@styles/components/Header/Header.module.scss';

const Selection = dynamic(() =>
  import('@components/header/components/Selection'),
);
import Icon3Dot from '@public/svg/3_dot.svg';
import MyDropdown from '@components/MyDropdown';

type Props = {
  optionSelections?: Array,
  value?: string,
};
export default React.forwardRef(ItemMore);
function ItemMore(props: Props, ref) {
  const { optionSelections, value } = props;
  return (
    <div ref={ref} className={styles.itemMore}>
      <MyDropdown
        DropdownComponent={Selection}
        dropdownContext={{ optionSelections, value }}
        tippyProps={{
          trigger: 'mouseenter',
          placement: 'bottom-start',
        }}>
        <div className="comCenter w-32px h-32px cursor-pointer">
          <Icon3Dot width={32} height={32} fill="#fff" />
        </div>
      </MyDropdown>
    </div>
  );
}

import React, { useState } from 'react';
import DrawerWrapper from '@components/DrawerWrapper';
import useLazyModal from '@hooks/common/useLazyModal';
import { ReactComponentElement } from 'react';
import InnerWrapper from '@components/MyDropdown/InnerWrapper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

type Props = {
  buttonDropdownClassName?: string,
  errorMsg?: string,
  disabled?: boolean,
  title?: string,
  children?: string,
  DropdownComponent?: ReactComponentElement,
  dropdownContext?: Object,
  appendTo?: Object,
  wrapperClass?: string,
  container?: any,
  popperStyle?: Object,
  innerWrapperStyle?: Object,
};

export default function MyDropdown(props: Props) {
  const {
    buttonDropdownClassName,
    errorMsg,
    disabled,
    children,
    DropdownComponent,
    dropdownContext = {},
    wrapperClass,
    title,
    container,
    popperStyle = {},
    innerWrapperStyle = {},
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [toggleDrawer, onToggleDrawer] = useState(false);
  const { renderModal, onHover } = useLazyModal();
  const isMobile = process.browser && window.innerWidth < 1024;
  return (
    <div className={`space-y-1 ${wrapperClass}`}>
      {!isMobile ? (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <div className="w-full flex flex-col">
            <Popper
              container={
                container ||
                (process.browser && document.getElementById('modal-root'))
              }
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
              disabled={disabled}
              modifiers={{
                offset: {
                  offset: '0, 20px',
                },
              }}
              style={{ zIndex: 10000, ...popperStyle }}
              open={!!anchorEl}>
              <DropdownComponent
                close={() => {
                  setAnchorEl && setAnchorEl(null);
                }}
                context={{
                  ...dropdownContext,
                }}
              />
            </Popper>
            <button
              disabled={disabled}
              className={`w-full ${buttonDropdownClassName}`}
              onClick={(e) => {
                setAnchorEl(anchorEl ? null : e.currentTarget);
              }}>
              {typeof children === 'function' ? children() : children}
            </button>
          </div>
        </ClickAwayListener>
      ) : (
        <div
          onMouseEnter={onHover}
          onClick={() => onToggleDrawer(!anchorEl)}
          className={`${buttonDropdownClassName}`}>
          {typeof children === 'function' ? children() : children}
        </div>
      )}
      {(renderModal || toggleDrawer) && (
        <DrawerWrapper
          open={toggleDrawer && !disabled}
          onClose={() => onToggleDrawer(false)}
          className="w-full h-full"
          customTopAnchorBottom>
          <div className="w-full flex" style={innerWrapperStyle}>
            <InnerWrapper title={title} close={() => onToggleDrawer(false)}>
              <DropdownComponent
                close={() => onToggleDrawer(false)}
                context={{ ...dropdownContext }}
              />
            </InnerWrapper>
          </div>
        </DrawerWrapper>
      )}
      {errorMsg && <div className="text-sm text-red-400">{errorMsg}</div>}
    </div>
  );
}

import styles from '@styles/components/ModalSignInSignUp/ModalSignInSignUp.module.scss';
import {
  descriptionSignIn,
  descriptionSignUp,
} from '@components/modals/ModalSignInSignUp/configs';
import { emailSupport, hotline } from '@utils/configs/Common/CommonConfigs';
import React from 'react';

type Props = {
  isSignIn?: boolean,
};

export default function LefBanner({ isSignIn }: Props) {
  return (
    <div className={styles.leftBanner}>
      <div className={styles.innerLefBanner}>
        <div className="text-24 font-bold font-textBold text-blue-main">
          {isSignIn ? 'Đăng nhập' : 'Đăng ký'}
        </div>
        <div className="text-13 font-text text-blue-main mt-16px">
          {isSignIn ? descriptionSignIn : descriptionSignUp}
        </div>
        <div
          className="text-16 font-textBold font-bold text-blue-main"
          style={{ marginTop: 48 }}>
          Gặp vấn đề?
        </div>
        <div className="text-13 font-text text-blue-main mt-16px">
          Số điện thoại hỗ trợ
        </div>
        <div className="text-13 font-text text-blue-main font-bold">
          {hotline}
        </div>
        <div className="flex flex-row items-center mt-16px space-x-1">
          <div className="text-13 font-text text-blue-main">Email hỗ trợ:</div>
          <div className="text-13 font-text text-blue-main">{emailSupport}</div>
        </div>
      </div>
      <img
        alt="bitmap"
        src="/images/bitmap.webp"
        className="absolute bottom-2"
      />
    </div>
  );
}

import { useSpring, animated } from 'react-spring';

type Props = {
  open?: boolean,
  className?: string,
  style?: Object,
  width?: number,
};
export default function HorizontalBar({
  open,
  className,
  style: outerStyle = {},
  width = 50,
}: Props) {
  const style = useSpring({ width: open ? width : 0, opacity: open ? 1 : 0 });
  return (
    <animated.div
      className={`h-4px bg-blue-main rounded-rd4px ${className}`}
      style={{ ...style, ...outerStyle }}
    />
  );
}

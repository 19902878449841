import styles from '@styles/components/SectionContainer.module.scss';
import { ReactChildren } from 'react';

type Props = {
  children: ReactChildren,
  containerClassname?: string,
  innerClassname?: string,
};

export default function SectionContainer({
  children,
  containerClassname,
  innerClassname,
}: Props) {
  return (
    <div className={`${styles.container} ${containerClassname}`}>
      <div className={`${styles.innerContainer} ${innerClassname}`}>
        {children}
      </div>
    </div>
  );
}

import { GET_USER_INFO_BY_FIELD } from '@schemas/queries/users';
import { useQuery } from '@apollo/client';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { AppContext } from '@utils/context/appContext';
import Avatar from '@components/Menu/components/Avatar';
import styles from '@styles/components/Header/Header.module.scss';
import Dropdown from '@components/Dropdown';
const ArrowHeader = dynamic(() => import('@public/svg/arrowDown.svg'));

const SelectionUserProfile = dynamic(() =>
  import('@components/header/components/SelectionUserProfile'),
);

const ChangePassword = dynamic(() =>
  import('@components/modals/changePassword'),
);

const ModalWrapper = dynamic(() => import('@components/ModalWrapper'));

const fields = [
  'fullName',
  'type',
  'companyName',
  'avatar { ...FileAllFields }',
];

export default function UserProfile() {
  const { isLogin } = useContext(AppContext);
  const { data } = useQuery(GET_USER_INFO_BY_FIELD(fields), { skip: !isLogin });
  const name = data?.user.fullName || data?.user.companyName;

  const [showModalChangePass, setShowModalChangePass] = useState(false);

  return (
    <>
      <div className={`${styles.verticalLine} mr-12px`} />
      <img src="/svg/bellWhite.svg" className="w-20px h-23px" alt="bell" />
      <div className={`${styles.verticalLine}`} style={{ margin: '0 12px' }} />
      <Dropdown
        DropdownComponent={SelectionUserProfile}
        dropdownContext={{ setShowModalChangePass }}
        tippyProps={{
          trigger: 'mouseenter',
          placement: 'bottom-start',
        }}>
        {/* <Link href="/thong-tin-cua-toi"> */}
        <a href="/thong-tin-cua-toi">
          <div className="flex flex row items-center space-x-1">
            <Avatar
              avatarUrl={data?.user.avatar?.originalUrl}
              type={data?.user.type}
              wrapperClass="w-32px h-32px bg-white"
            />
            <div className="text-white text-13 font-textBold font-bold whitespace-nowrap">
              {name}
            </div>
            <ArrowHeader className="w-16px h-16px" />
          </div>
        </a>

        {/* </Link> */}
      </Dropdown>
      {showModalChangePass && (
        <ModalWrapper
          className="rounded-rd8px max-w-95%"
          open={showModalChangePass}
          onClose={() => setShowModalChangePass(false)}>
          <ChangePassword close={() => setShowModalChangePass(false)} />
        </ModalWrapper>
      )}
    </>
  );
}

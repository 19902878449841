import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { getRouteSearchList } from '@utils/helper';
import {
  allPostType,
  allProjectType,
  allRealEstateType,
} from '@utils/configs/SearchList/SearchListConfigs';
import { routeSearchList } from '@utils/configs/SearchList/const';
const Phone = dynamic(() => import('@public/svg/phone2.svg'));
const IconEmail = dynamic(() => import('@public/svg/ico_mail.svg'));
const IconLocation = dynamic(() => import('@public/svg/location2.svg'));

const IconFacebook = dynamic(() => import('@public/svg/ftFacebook.svg'));
const IconZalo = dynamic(() => import('@public/svg/ftZalo.svg'));
const IconYoutuble = dynamic(() => import('@public/svg/ftYoutube.svg'));
const IconTiktok = dynamic(() => import('@public/svg/ftTiktok.svg'));
const IconViber = dynamic(() => import('@public/svg/ftViber.svg'));
const IconTwitter = dynamic(() => import('@public/svg/ftTwitter.svg'));

export const useFooterNavigations = () => {
  const router = useRouter();
  return {
    buy: {
      title: 'BÁN',
      submenus: [
        {
          title: 'Bất động sản thanh lý',
          href: '/404',
        },
        {
          title: 'Bất động sản chính chủ',
          href: getRouteSearchList({
            typeOfDemand: 'ForSale',
            realEstateType: allRealEstateType,
            postType: allPostType,
            isAuthorizedRe: true,
          }),
        },
        {
          title: 'Tất cả nhà đất bán',
          href: getRouteSearchList({
            typeOfDemand: 'ForSale',
            realEstateType: allRealEstateType,
            postType: allPostType,
          }),
        },
        {
          title: 'Nhà phố',
          href: getRouteSearchList({
            typeOfDemand: 'ForSale',
            realEstateType: 'townhouse',
            postType: allPostType,
          }),
        },
        {
          title: 'Biệt thự',
          href: getRouteSearchList({
            typeOfDemand: 'ForSale',
            realEstateType: 'villa',
            postType: allPostType,
          }),
        },
        {
          title: 'Cao ốc',
          href: getRouteSearchList({
            typeOfDemand: 'ForSale',
            realEstateType: 'building',
            postType: allPostType,
          }),
        },
        {
          title: 'Khách sạn',
          href: getRouteSearchList({
            typeOfDemand: 'ForSale',
            realEstateType: 'hotel',
            postType: allPostType,
          }),
        },
        {
          title: 'Nhà xưởng',
          href: getRouteSearchList({
            typeOfDemand: 'ForSale',
            realEstateType: 'warehouse',
            postType: allPostType,
          }),
        },
        {
          title: 'Đất nền',
          href: getRouteSearchList({
            typeOfDemand: 'ForSale',
            realEstateType: 'land',
            postType: allPostType,
          }),
        },
        {
          title: 'Căn hộ - Chung cư',
          href: getRouteSearchList({
            typeOfDemand: 'ForSale',
            realEstateType: 'apartment',
            postType: allPostType,
          }),
        },
        {
          title: 'Dự án',
          href: getRouteSearchList({
            typeOfDemand: 'project',
            realEstateType: allProjectType,
            postType: allPostType,
          }),
        },
        {
          title: 'Shophouse',
          href: getRouteSearchList({
            typeOfDemand: 'ForSale',
            realEstateType: 'shophouse',
            postType: allPostType,
          }),
        },
        {
          title: 'Penthouse',
          href: getRouteSearchList({
            typeOfDemand: 'ForSale',
            realEstateType: 'penthouse',
            postType: allPostType,
          }),
        },
      ],
    },
    rent: {
      title: 'THUÊ',
      submenus: [
        {
          title: 'Bất động sản thanh lý',
          href: '/404',
        },
        {
          title: 'Bất động sản chính chủ',
          href: getRouteSearchList({
            typeOfDemand: 'ForRent',
            realEstateType: allRealEstateType,
            postType: allPostType,
            isAuthorizedRe: true,
          }),
        },
        {
          title: 'Tất cả nhà đất thuê',
          href: getRouteSearchList({
            typeOfDemand: 'ForRent',
            realEstateType: allRealEstateType,
            postType: allPostType,
          }),
        },
        {
          title: 'Nhà phố',
          onPress: () => {
            router.push({
              pathname: `/${routeSearchList}`,
              query: '?typeHome=townhouse&isForSell=ForRent',
            });
          },
          href: getRouteSearchList({
            typeOfDemand: 'ForRent',
            realEstateType: 'townhouse',
            postType: allPostType,
          }),
        },
        {
          title: 'Biệt thự',
          href: getRouteSearchList({
            typeOfDemand: 'ForRent',
            realEstateType: 'villa',
            postType: allPostType,
          }),
        },
        {
          title: 'Cao ốc',
          href: getRouteSearchList({
            typeOfDemand: 'ForRent',
            realEstateType: 'building',
            postType: allPostType,
          }),
        },
        {
          title: 'Khách sạn',
          href: getRouteSearchList({
            typeOfDemand: 'ForRent',
            realEstateType: 'hotel',
            postType: allPostType,
          }),
        },
        {
          title: 'Nhà xưởng',
          href: getRouteSearchList({
            typeOfDemand: 'ForRent',
            realEstateType: 'warehouse',
            postType: allPostType,
          }),
        },
        {
          title: 'Đất nền',
          href: getRouteSearchList({
            typeOfDemand: 'ForRent',
            realEstateType: 'land',
            postType: allPostType,
          }),
        },
        {
          title: 'Phòng trọ',
          href: getRouteSearchList({
            typeOfDemand: 'ForRent',
            realEstateType: 'motel',
            postType: allPostType,
          }),
        },
        {
          title: 'Căn hộ - Chung cư',
          href: getRouteSearchList({
            typeOfDemand: 'ForRent',
            realEstateType: 'apartment',
            postType: allPostType,
          }),
        },
        {
          title: 'Shophouse',
          href: getRouteSearchList({
            typeOfDemand: 'ForRent',
            realEstateType: 'shophouse',
            postType: allPostType,
          }),
        },
        {
          title: 'Penthouse',
          href: getRouteSearchList({
            typeOfDemand: 'ForRent',
            realEstateType: 'penthouse',
            postType: allPostType,
          }),
        },
      ],
    },
    service: {
      title: 'DỊCH VỤ',
      submenus: [
        { title: 'CRM', href: '/404' },
        {
          title: 'Định giá',
          href: '/dinh-gia',
          mustLogin: true,
        },
        {
          title: 'Chat',
          href: '/404',
          mustLogin: true,
        },
        {
          title: 'Bản đồ thế giới',
          href: '/ban-do',
          mustLogin: true,
        },
        {
          title: 'Bản đồ quy hoạch',
          href: '/ban-do?mapType=internal',
          mustLogin: true,
        },
        {
          title: 'Mạng lưới thành viên',
          href: '/mang-luoi-cua-toi',
          mustLogin: true,
        },
        {
          title: 'Cổng thanh toán',
          href: '/404',
        },
        {
          title: 'Lịch nhắc hẹn',
          href: '/404',
          mustLogin: true,
        },
        {
          title: 'Cộng đồng môi giới',
          href: '/404',
        },
        {
          title: 'Mạng xã hội',
          href: '/404',
        },
        {
          title: 'Livestream',
          href: '/404',
        },
      ],
    },
  };
};
export const footerContact = {
  phone: {
    title: 'Điện thoại',
    Icon: Phone,
    values: [{ title: '0789333322' }],
  },
  mail: {
    title: 'Email',
    Icon: IconEmail,
    values: [{ title: 'contact@realtech247.vn' }],
  },
  address: {
    title: 'Địa chỉ',
    Icon: IconLocation,
    values: [{ title: '212 Cộng Hòa, P. 12, Q. Tân Bình, Tp. Hồ Chí Minh' }],
  },
};
export const socialConfigs = [
  {
    Icon: IconFacebook,
  },
  {
    Icon: IconZalo,
  },
  {
    Icon: IconYoutuble,
  },
  {
    Icon: IconTiktok,
  },
  {
    Icon: IconViber,
  },
  {
    Icon: IconTwitter,
  },
];

export const footerNavigationSecond = {
  above: {
    menus: [
      {
        title: 'Giới thiệu',
        margin: true,
        url: '/thong-tin-chung#gioi-thieu',
      },
      {
        title: 'Các dịch vụ chính',
        url: '/thong-tin-chung#cac-dich-vu-chinh',
      },
      { title: 'Liên hệ', url: '/thong-tin-chung#lien-he' },
      {
        title: 'Báo giá và hỗ trợ',
        url: '/thong-tin-chung#bao-gia-va-ho-tro',
      },
      {
        title: 'Những câu hỏi thường gặp',
        url: '/thong-tin-chung#nhung-cau-hoi-thuong-gap',
      },
      {
        title: 'Điều khoản thỏa thuận',
        url: '/thong-tin-chung#dieu-khoan-thoa-thuan-3.1',
      },
      {
        title: 'Trách nhiệm và nghĩa vụ của người sử dụng',
        url: '/thong-tin-chung#trach-nhiem-va-nghia-vu',
      },
    ],
  },
  below: {
    menus: [
      {
        title: 'Sở hữu trí tuệ',
        margin: true,
        url: '/thong-tin-chung#so-huu-tri-tue',
      },
      {
        title: 'Chính sách bảo mật',
        url: '/thong-tin-chung#chinh-sach-bao-mat',
      },
      {
        title: 'Xác nhập hoặc nhượng quyền',
        url: '/thong-tin-chung#xac-nhan-hoac-nhuong-quyen',
      },
      {
        title: 'Cơ chế giải quyết khiếu nại',
        url: '/thong-tin-chung#co-che-giai-quyet-khieu-nai',
      },
      {
        title: 'Quy định khi đăng tin và quảng cáo',
        url: '/thong-tin-chung#quy-dinh-dang-tin-va-quang-cao',
      },
    ],
  },
};

export const footerIntroduce = {
  rows: [
    {
      title:
        'Giấy ĐKKD số: 0316747912 Do Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh cấp lần đầu ngày 15/03/2021.',
    },
    {
      title:
        'Chịu trách nhiệm nội dung: Ông Võ Văn Tính - ® Ghi rõ nguồn “realtech247.com” khi phát hành lại thông tin từ website này.',
    },
    {
      title:
        'Toàn bộ quy chế, quy định giao dịch chung được đăng tải trên website áp dụng từ ngày 15/03/2021.',
    },
  ],
};

import styles from '@styles/components/Header/Header.module.scss';
import React from 'react';

type Props = {
  gotoLogin?: Function,
};

export default function LoginAndSignUp({ gotoLogin }: Props) {
  return (
    <div className="flex flex-row items-center cursor-pointer space-x-1 ml-16px">
      <button
        onClick={() => {
          gotoLogin(false);
        }}
        className={`${styles.whiteText} heading5`}>
        Đăng ký/
      </button>
      <button
        onClick={() => {
          gotoLogin();
        }}
        className={`${styles.whiteText} heading5`}>
        Đăng nhập
      </button>
    </div>
  );
}

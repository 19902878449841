import React, {
  ImgHTMLAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react';
import { ButtonHTMLAttributes } from 'react';
import { useChatContext } from '@utils/context/chatContext';
import { useAppContext } from '@utils/context/appContext';
import useLazyModal from '@hooks/common/useLazyModal';
import useOpenModalLogin from '@hooks/auth/login/useOpenModalLogin';

import ModalSignInSignUp from '@components/modals/ModalSignInSignUp';

export default React.forwardRef(QuickButton);

function QuickButton(_, ref) {
  const { showModal, gotoLogin, setShowModal, modalRef } = useOpenModalLogin();
  const { onHover } = useLazyModal();

  const { setShowChat, showChat } = useChatContext(),
    { isLogin } = useAppContext();

  const openChat = useCallback(() => {
    if (!isLogin) {
      gotoLogin();
    } else {
      setShowChat(!showChat);
    }
  }, [isLogin, showChat]);

  useImperativeHandle(ref, () => ({
    openChat,
  }));

  useEffect(() => {
    if (!isLogin) {
      setShowChat(false);
    }
  }, [isLogin]);

  return (
    <div
      style={{ zIndex: 1000 }}
      className="hidden lg:flex flex-col fixed bottom-16px left-16px">
      <Item
        src="/svg/chat3.svg"
        onMouseEnter={onHover}
        onClick={() => {
          if (!isLogin) {
            gotoLogin();
          } else {
            setShowChat(!showChat);
          }
        }}
      />
      <ModalSignInSignUp
        ref={modalRef}
        open={showModal}
        onClose={() => setShowModal(false)}
        configs={{ isSignIn: true }}
      />
    </div>
  );
}

type ItemProps = {
  wrapperClass?: string,
} & ImgHTMLAttributes<HTMLImageElement> &
  ButtonHTMLAttributes<HTMLButtonElement>;

function Item({ wrapperClass, onClick, onMouseEnter, ...rest }: ItemProps) {
  return (
    <button
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      className={`flex w-56px h-56px rounded-rd50 bg-white border border-blue-main items-center justify-center ${wrapperClass}`}>
      <img alt="icon" {...rest} />
    </button>
  );
}

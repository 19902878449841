import styles from '@styles/components/ModalSignInSignUp/ModalSignInSignUp.module.scss';

import {
  suggestSignUp,
  suggestSignIn,
} from '@components/modals/ModalSignInSignUp/configs';

type Props = {
  isSignIn?: boolean,
  setIsSignIn?: Function,
};

export default function BottomNote({ isSignIn, setIsSignIn }: Props) {
  return (
    <div className={styles.bottomNote}>
      <div className="bodyText flex flex-row items-center">
        {isSignIn ? suggestSignUp : suggestSignIn}
      </div>
      <button
        onClick={() => setIsSignIn(!isSignIn)}
        className={`text-13 text-blue-main underline font-bold font-textBold ${
          !isSignIn && 'uppercase'
        }`}>
        {isSignIn ? 'Đăng ký ngay' : 'Đăng Nhập'}
      </button>
    </div>
  );
}

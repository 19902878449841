import styles from '@styles/components/Header/Header.module.scss';

type Props = {
  onPress?: Function,
  wrapperStyle?: string,
  title?: string,
};

export default function Press(props: Props) {
  const { onPress, wrapperStyle, title } = props;
  return (
    <div
      className={`flex flex-col items-center cursor-pointer ${wrapperStyle}`}
      onClick={onPress}>
      <div className={styles.titleMenu}>{title}</div>
    </div>
  );
}

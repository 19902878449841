import React from 'react';
import styles from '@styles/components/Header/Header.module.scss';

import BellIcon from '@public/svg/bell.svg';
import { useRouter } from 'next/router';
import isEqual from 'lodash/isEqual';

export default React.memo(Notification, isEqual);

function Notification() {
  // const history = useHistory();
  // const { data: dataNotification } = useSubscriptionNotification({
  //   searchValue: '',
  //   filter: 'all',
  // });

  const router = useRouter();

  // const totalNotification = dataNotification?.myNotifications?.statistics?.all;

  const totalNotification = 0;

  const countNoti = totalNotification > 9 ? '9+' : totalNotification;

  return (
    <div
      className={styles.notification}
      onClick={() => {
        router.push('/notifications');
      }}>
      <BellIcon className="w-20px h-23px" />
      {totalNotification > 0 && (
        <div
          className="w-18px h-18px rounded-rd50 absolute -top-6px -right-6px flex flex-col items-center justify-center"
          style={{ backgroundColor: '#FF0000' }}>
          <div className="count-notification">{countNoti}</div>
        </div>
      )}
    </div>
  );
}

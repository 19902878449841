import styles from '@styles/components/Footer.module.scss';
import SectionContainer from '@components/SectionContainer';
import dynamic from 'next/dynamic';

const FooterCopyright = dynamic(() =>
  import('@components/Footer/components/footerCopyright'),
);
const FooterContact = dynamic(() =>
  import('@components/Footer/components/footerContact'),
);
const FooterNavigationColumn = dynamic(() =>
  import('@components/Footer/components/footerNavigationColumn'),
);
const DownloadComponent = dynamic(() =>
  import('@components/Footer/components/download'),
);
const FooterNavigationRows = dynamic(() =>
  import('@components/Footer/components/footerNavigationRows'),
);
const FooterIntroduceRows = dynamic(() =>
  import('@components/Footer/components/footerIntroduceRows'),
);

import {
  useFooterNavigations,
  footerContact,
  socialConfigs,
  footerNavigationSecond,
  footerIntroduce,
} from '@utils/configs/Footer/footerConfigs';
import LogoRealTech from '@public/svg/logoRealTech.svg';

export default function Index() {
  return (
    <footer className="w-full hidden lg:flex flex-col bg-blue-main">
      <SectionContainer containerClassname={styles.container}>
        <FooterLink />
        <FooterNavigationRow />
        <FooterIntroduce />
      </SectionContainer>
      <FooterCopyright />
    </footer>
  );
}

function FooterLink() {
  const { buy, rent, service } = useFooterNavigations();
  return (
    <div className="flex flex-row w-full p-vertical32px border-b border-blue-main">
      <div className="flex flex-col mr-24px">
        <LogoRealTech width={123} height={40} className="mb-16px" />
        <FooterContact {...footerContact.phone} />
        <FooterContact {...footerContact.mail} />
        <FooterContact {...footerContact.address} />
        <div
          style={{ backgroundImage: 'url("/images/authentication.png")' }}
          className="w-119px h-45px mt-24px cursor-pointer"
        />
      </div>
      <div className="flex flex-row flex-1">
        <FooterNavigationColumn {...buy} />
        <FooterNavigationColumn wrapperStyle="lg:mx-4px xl:mx-10px" {...rent} />
        <FooterNavigationColumn {...service} />
        <div className="flex-1 flex flex-col">
          {/*<div className="text-white font-bold font-text text-15">NGÔN NGỮ</div>*/}
          {/*<Language />*/}
          <DownloadComponent />
          <div style={{ marginTop: 32 }}>
            <div className="text-white font-bold font-text text-15">
              KẾT NỐI VỚI CHÚNG TÔI:
            </div>
            <div className="flex flex-row items-center mt-8px w-full justify-around">
              {socialConfigs.map((item, index) => {
                const { Icon } = item;
                return (
                  <Icon key={index} className="w-32px h-32px cursor-pointer" />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function FooterNavigationRow() {
  return (
    <div className="flex flex-col w-full pt-12px items-center">
      <div className="w-full h-px bg-white mb-12px" />
      <FooterNavigationRows {...footerNavigationSecond.above} />
      <FooterNavigationRows {...footerNavigationSecond.below} />
    </div>
  );
}

function FooterIntroduce() {
  return (
    <div className="mt-16px mb-24px w-full">
      <FooterIntroduceRows {...footerIntroduce} />
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import {
  newsConfigs,
  projectConfigsV2,
  demandConfigs,
  useMoreConfigs,
  usePostSelections,
} from '@components/header/Configs';
import RealEstateCommonConfigs from '@utils/configs/RealEstateConfiguarations/RealEstateCommonConfigs.json';
import styles from '@styles/components/Header/Header.module.scss';
import { useContext } from 'react';
import { AppContext } from '@utils/context/appContext';
import LoginAndSignUp from '@components/header/components/LoginAndSignUp';
const Selection = dynamic(() => import('./Selection'));

import IconFillArrow from '@public/svg/fillArrrowDown.svg';
import { getRouteSearchList, isMobile } from '@utils/helper';
import {
  allPostType,
  allRealEstateType,
  allProjectType,
} from '@utils/configs/SearchList/SearchListConfigs';

import Press from '@components/header/components/Press';

import ItemMore from '@components/header/components/ItemMore';
import ItemMenu from '@components/header/components/ItemMenu';
import UserProfile from '@components/header/components/UserProfile';
import useOpenModalLogin from '@hooks/auth/login/useOpenModalLogin';
import Dropdown from '@components/Dropdown';
import ModalSignInSignUp from '@components/modals/ModalSignInSignUp';
import { routeSearchList } from '@utils/configs/SearchList/const';
const UpgradeCompanyModal = dynamic(() =>
  import('@components/modals/UpgradeCompany'),
);

const forSaleRouteConfigs = [
  `/${routeSearchList}?isForSell=ForSale`,
  `/${routeSearchList}?isForSell=ForSale&typeHome=townhouse`,
  `/${routeSearchList}?isForSell=ForSale&typeHome=villa`,
  `/${routeSearchList}?isForSell=ForSale&typeHome=building`,
  `/${routeSearchList}?isForSell=ForSale&typeHome=hotel`,
  `/${routeSearchList}?isForSell=ForSale&typeHome=warehouse`,
  `/${routeSearchList}?isForSell=ForSale&typeHome=land`,
  `/${routeSearchList}?isForSell=ForSale&typeHome=apartment`,
  `/${routeSearchList}?isForSell=ForSale&typeHome=shophouse`,
  `/${routeSearchList}?isForSell=ForSale&typeHome=penthouse`,
];

const newsRouteConfigs = [
  '/search-list-materials',
  '/seek-legal-advice',
  '/search-list-gold',
  '/search-list-car',
  '/search-list-banks',
];

const forRentRouteConfigs = [
  `/${routeSearchList}?isForSell=ForRent`,
  `/${routeSearchList}?isForSell=ForRent&typeHome=townhouse`,
  `/${routeSearchList}?isForSell=ForRent&typeHome=villa`,
  `/${routeSearchList}?isForSell=ForRent&typeHome=building`,
  `/${routeSearchList}?isForSell=ForRent&typeHome=hotel`,
  `/${routeSearchList}?isForSell=ForRent&typeHome=warehouse`,
  `/${routeSearchList}?isForSell=ForRent&typeHome=land`,
  `/${routeSearchList}?isForSell=ForRent&typeHome=apartment`,
  `/${routeSearchList}?isForSell=ForRent&typeHome=shophouse`,
  `/${routeSearchList}?isForSell=ForRent&typeHome=penthouse`,
  `/${routeSearchList}?isForSell=ForRent&typeHome=motel`,
];

const projectRouteConfigs = [
  `/${routeSearchList}?typeHome=complex&typeHome=landProject&typeHome=industrialArea&typeHome=buildingProject&typeHome=shoppingMall`,
  `/${routeSearchList}?isForSell=ForSale&typeHome=complex&typeHome=landProject&typeHome=industrialArea&typeHome=buildingProject&typeHome=shoppingMall`,
  `/${routeSearchList}?isForSell=ForRent&typeHome=complex&typeHome=landProject&typeHome=industrialArea&typeHome=buildingProject&typeHome=shoppingMall`,
];

const ForSaleRealEstateType = ({
  typeOfDemand,
  configsRealEstate = [],
  isForSell,
}) => {
  return configsRealEstate?.map((item) => {
    return {
      label: item.value,
      pathname: getRouteSearchList({
        typeOfDemand,
        realEstateType: item.key,
        postType: allPostType,
      }),
      isForSell,
      realEstateTypes: item.key,
    };
  });
};

export default function MenuSelection() {
  const router = useRouter(),
    { pathname, query } = router;

  const { isLogin } = useContext(AppContext);

  const [openUpdateCompanyInfo, setOpenUpdateCompanyInfo] = useState(false);

  const projectForSaleTypes = RealEstateCommonConfigs?.projectForSaleTypes;

  const route = `${pathname}${query}`;
  const {
    showModal,
    setShowModal,
    gotoLogin,
    modalRef: modalSignUpRef,
  } = useOpenModalLogin();

  useEffect(() => {
    if (!isLogin && !!query?.ref?.length && !isMobile) {
      gotoLogin(false);
    }
  }, [isLogin]);

  const forSaleRealEstateTypeConfig = ForSaleRealEstateType({
    typeOfDemand: 'ForSale',
    configsRealEstate: RealEstateCommonConfigs.forSellRealEstateTypes,
    isForSell: 'ForSale',
  });
  const forRentRealEstateTypeConfig = ForSaleRealEstateType({
    typeOfDemand: 'ForRent',
    configsRealEstate: RealEstateCommonConfigs.forRentRealEstateTypes,
    isForSell: 'ForRent',
  });

  const finalProjectSelections = projectConfigsV2.map((item) => {
    const foundConfig = projectForSaleTypes?.find((e) => e.key === item.key);
    if (foundConfig) {
      return {
        ...foundConfig,
        ...item,
        key: item.key,
        label: foundConfig.value,
        isForSell: 'project',
        realEstateTypes: item.key,
      };
    }
  });

  const postSelections = usePostSelections(setOpenUpdateCompanyInfo);

  const moreConfigs = useMoreConfigs();
  return (
    <div className={styles.innerHeaderRightContent}>
      <div className="flex flex-row items-center justify-end flex-1 space-x-4">
        <ItemMenu
          value={route}
          activeWithRoute={forSaleRouteConfigs.includes(route)}
          href={`${getRouteSearchList({
            typeOfDemand: 'ForSale',
            realEstateType: allRealEstateType,
            postType: allPostType,
          })}?isForSell=ForSale`}
          title="Nhà bán"
          optionSelections={forSaleRealEstateTypeConfig}
        />
        <ItemMenu
          value={route}
          activeWithRoute={forRentRouteConfigs.includes(route)}
          href={`${getRouteSearchList({
            typeOfDemand: 'ForRent',
            realEstateType: allRealEstateType,
            postType: allPostType,
          })}?isForSell=ForRent`}
          title="Nhà thuê"
          optionSelections={forRentRealEstateTypeConfig}
        />
        <ItemMenu
          value={route}
          activeWithRoute={projectRouteConfigs.includes(route)}
          href={`${getRouteSearchList({
            typeOfDemand: 'project',
            realEstateType: allProjectType,
            postType: allPostType,
          })}?isForSell=project`}
          title={'Dự án'}
          optionSelections={finalProjectSelections || []}
        />
        <div className={styles.rowHideMore}>
          <ItemMenu
            value={route}
            activeWithRoute={[`/${routeSearchList}`].includes(pathname)}
            href={`${getRouteSearchList({
              typeOfDemand: 'ToBuy',
              realEstateType: allRealEstateType,
              postType: allPostType,
            })}?isForSell=ToBuy`}
            title={'Nhu cầu'}
            optionSelections={demandConfigs}
          />
        </div>
        <Press
          title="Bản đồ"
          onPress={async () => {
            if (!isLogin) {
              modalSignUpRef?.current?.setIsSignIn(true);
              gotoLogin();
            } else {
              await router.push('/ban-do');
            }
          }}
        />
        <Press
          onPress={async () => {
            if (!isLogin) {
              modalSignUpRef?.current?.setIsSignIn(true);
              gotoLogin();
            } else {
              await router.push('/dinh-gia');
            }
          }}
          title="Định giá"
        />
        <Press title="CRM" wrapperStyle="mr-16px" href="/404" />
        <div className={styles.rowHideMore}>
          <ItemMenu
            value={route}
            activeWithRoute={newsRouteConfigs.includes(route)}
            title={'Tin tức'}
            href="/404"
            optionSelections={newsConfigs}
          />
        </div>
        <ItemMore value={route} optionSelections={moreConfigs} />
        <div
          onClick={() => {
            if (!isLogin) {
              modalSignUpRef?.current?.setIsSignIn(true);
              setShowModal(true);
            } else {
              router.push('/mang-luoi-cua-toi');
            }
          }}
          className={`${styles.btnNextWork} boldBodyText cursor-pointer`}>
          Mạng lưới
        </div>
        <Dropdown
          style="w-128px"
          DropdownComponent={Selection}
          tippyProps={{
            trigger: 'mouseenter',
            placement: 'bottom-start',
          }}
          dropdownContext={{
            optionSelections: postSelections,
            // isRequiredLogin: !isLogin,
            setShowIsLogin: setShowModal,
          }}>
          <button className={`${styles.btnPost} boldBodyText`}>
            Đăng tin <IconFillArrow className="w-10px h-6px" />
          </button>
        </Dropdown>
        <div className="flex flex-row items-center">
          {isLogin ? <UserProfile /> : <LoginAndSignUp gotoLogin={gotoLogin} />}
        </div>
        <ModalSignInSignUp
          ref={modalSignUpRef}
          open={showModal}
          onClose={() => setShowModal(false)}
          configs={{ isSignIn: true }}
        />
        <UpgradeCompanyModal
          openModal={openUpdateCompanyInfo}
          setOpenModal={setOpenUpdateCompanyInfo}
        />
      </div>
    </div>
  );
}

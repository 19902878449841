import React from 'react';
import LeftBanner from './LefBanner';
import Switch from '@components/modals/ModalSignInSignUp/components/Switch';
import BottomNote from '@components/modals/ModalSignInSignUp/components/BottomNote';
import dynamic from 'next/dynamic';
import styles from '@styles/components/ModalSignInSignUp/ModalSignInSignUp.module.scss';

const SignIn = dynamic(
  () => import('@components/modals/ModalSignInSignUp/components/SignIn'),
  { ssr: false },
);
const SignUp = dynamic(
  () => import('@components/modals/ModalSignInSignUp/components/SingUp'),
  { ssr: false },
);

type WrapperProps = {
  isSignIn?: boolean,
  setIsSignIn?: Function,
};
export default function Wrapper(props: WrapperProps) {
  const { isSignIn, setIsSignIn } = props;

  return (
    <div className={styles.modalContainer}>
      <LeftBanner isSignIn={isSignIn} />
      <div className="flex flex-1 flex-col">
        <Switch isSignIn={isSignIn} setIsSignIn={setIsSignIn} />
        <div className="flex flex-col flex-1 overflow-y-auto">
          {isSignIn ? <SignIn /> : <SignUp />}
        </div>
        <BottomNote isSignIn={isSignIn} setIsSignIn={setIsSignIn} />
      </div>
    </div>
  );
}

import React from 'react';
import dynamic from 'next/dynamic';
import styles from '@styles/components/Header/Header.module.scss';
import Dropdown from '@components/Dropdown';
const ArrowHeader = dynamic(() => import('@public/svg/arrowDown.svg'));

const Selection = dynamic(() => import('./Selection'));

type Props = {
  title?: string,
  placement?: string,
  DropdownComponent?: React.Component,
  optionSelections?: Array,
  activeWithRoute?: boolean,
  wrapperStyle?: {},
  onPress?: Function,
  value?: string,
  href?: string,
};

export default function ItemMenu(props: Props) {
  const {
    title,
    DropdownComponent,
    optionSelections,
    wrapperStyle,
    value,
    href = '#',
  } = props;

  return (
    <div className={`items-center ${wrapperStyle}`}>
      <Dropdown
        style={{ cursor: 'pointer' }}
        DropdownComponent={DropdownComponent || Selection}
        dropdownContext={{ optionSelections, value }}
        tippyProps={{
          trigger: 'mouseenter',
          placement: 'bottom-start',
        }}>
        {({ show }) => {
          return (
            <a
              href={href}
              className="flex flex-row items-center cursor-pointer">
              <div className={styles.titleMenu}>{title}</div>
              <ArrowHeader
                className={`${styles.iconArrowHeader} ${
                  show ? styles.activeArrowHeader : ''
                }`}
              />
            </a>
          );
        }}
      </Dropdown>
    </div>
  );
}

import { ReactChildren, ReactNode } from 'react';
import Footer from '@components/Footer';
import Header from '@components/header/Header';
import dynamic from 'next/dynamic';
import { useChatContext } from '@utils/context/chatContext';
import React from 'react';

const Chat = dynamic(() => import('@components/Chat'));
import QuickButton from '@components/QuickButton';
import { isMobile } from '@utils/helper';

type LayoutProps = {
  children: ReactChildren,
  configs?: LayoutConfigs,
};

type LayoutConfigs = {
  fixedPage: boolean,
  hideHeader: boolean,
  hideFooter: boolean,
  headerClass?: string,
  hideQuickButton?: boolean,
};

global.quickButtonRef = React.createRef();

function Layout({ children, configs = {} }: LayoutProps) {
  const { fixedPage, hideHeader, hideFooter, headerClass, hideQuickButton } =
    configs;

  const { showChat } = useChatContext();

  return (
    <div className="flex flex-col flex-1">
      {!hideHeader && <Header wrapperClass={headerClass} />}
      <main className={fixedPage ? 'fix-content' : 'flex-grow'}>
        {children}
      </main>
      {!hideFooter && <Footer />}
      {showChat && !isMobile && <Chat />}
      {!hideQuickButton && <QuickButton ref={global.quickButtonRef} />}
    </div>
  );
}

export const getLayout = (
  page: ReactChildren,
  configs: LayoutConfigs,
): ReactNode => {
  return <Layout configs={configs}>{page}</Layout>;
};

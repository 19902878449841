import React, { useCallback, useState, useImperativeHandle } from 'react';
import dynamic from 'next/dynamic';
import { ModalLoginContext, SignInContext } from './context';
import Wrapper from '@components/modals/ModalSignInSignUp/components/Wrapper';
const UpdatePremiumAccountModal = dynamic(
  () => import('@components/UpdatePremiumAccount'),
  { ssr: false },
);
const SignUpSuccess = dynamic(() => import('./components/signUpSuccess'), {
  ssr: false,
});

const ModalWrapper = dynamic(() => import('@components/ModalWrapper'), {
  ssr: false,
});

const ForgotPasswordModal = dynamic(
  () => import('@components/modals/ForgotPassword'),
  { ssr: false },
);

type ModalSignInSignUpProps = {
  open?: boolean,
  onClose?: boolean,
  configs: {
    isSignIn?: boolean,
  },
};

export default React.forwardRef(ModalSignInSignUp);

function ModalSignInSignUp(
  { open, onClose, configs }: ModalSignInSignUpProps,
  ref,
) {
  const { isSignIn: outerIsSignIN } = configs;
  const [isSignIn, setIsSignIn] = useState(outerIsSignIN),
    [socialData, setSocialData] = useState({}),
    [showModalSuccess, setShowModalSuccess] = useState(false),
    [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false),
    [signUpResult, setSignUpResult] = useState({});

  useImperativeHandle(ref, () => ({
    setIsSignIn,
  }));

  const closeModalSignInSignUp = useCallback(() => {
    onClose();
    setSocialData({});
  }, []);

  const [openModalUpdateAcc, setOpenModalUpdateAcc] = useState(false);
  return (
    <ModalLoginContext.Provider
      value={{
        open,
        closeModal: closeModalSignInSignUp,
        setSignUpResult,
        setShowModalSuccess,
        setShowForgotPasswordModal,
        setOpenModalUpdateAcc,
      }}>
      <>
        {open && (
          <ModalWrapper
            className="rounded-rd4px max-w-95%"
            open={open}
            onClose={closeModalSignInSignUp}>
            <SignInContext.Provider
              value={{ setIsSignIn, socialData, setSocialData }}>
              <Wrapper isSignIn={isSignIn} setIsSignIn={setIsSignIn} />
            </SignInContext.Provider>
          </ModalWrapper>
        )}
        {showModalSuccess && (
          <ModalWrapper
            className="rounded-rd4px max-w-95%"
            open={showModalSuccess}
            onClose={() => setShowModalSuccess(false)}>
            <SignUpSuccess
              close={() => {
                setShowModalSuccess(false);
                setOpenModalUpdateAcc(true);
              }}
              configs={{ ...signUpResult }}
            />
          </ModalWrapper>
        )}
        {showForgotPasswordModal && (
          <ModalWrapper
            className="rounded-rd4px max-w-95%"
            open={showForgotPasswordModal}
            onClose={() => setShowForgotPasswordModal(false)}>
            <ForgotPasswordModal
              close={() => setShowForgotPasswordModal(false)}
            />
          </ModalWrapper>
        )}
        <UpdatePremiumAccountModal
          openModal={openModalUpdateAcc}
          setOpenModal={setOpenModalUpdateAcc}
        />
      </>
    </ModalLoginContext.Provider>
  );
}

import { realEstateTypeConfigs as realEstate } from '@utils/configs/RealEstateConfiguarations/realEstate';
import { getRouteSearchList } from '@utils/helper';
import {
  allPostType,
  allRealEstateType,
} from '@utils/configs/SearchList/SearchListConfigs';
import { useAppContext } from '@utils/context/appContext';
import { useQuery } from '@apollo/client';
import { GET_USER_INFO_BY_FIELD } from '@schemas/queries/users';

export const projectConfigsV2 = [
  {
    key: realEstate.complex,
    pathname: getRouteSearchList({
      typeOfDemand: 'project',
      realEstateType: realEstate.complex,
      postType: allPostType,
    }),
  },
  {
    key: realEstate.buildingProject,
    pathname: getRouteSearchList({
      typeOfDemand: 'project',
      realEstateType: realEstate.buildingProject,
      postType: allPostType,
    }),
  },
  {
    key: realEstate.landProject,
    pathname: getRouteSearchList({
      typeOfDemand: 'project',
      realEstateType: realEstate.landProject,
      postType: allPostType,
    }),
  },
  {
    key: realEstate.industrialArea,
    pathname: getRouteSearchList({
      typeOfDemand: 'project',
      realEstateType: realEstate.industrialArea,
      postType: allPostType,
    }),
  },
  {
    key: realEstate.shoppingMall,
    pathname: getRouteSearchList({
      typeOfDemand: 'project',
      realEstateType: realEstate.shoppingMall,
      postType: allPostType,
    }),
  },
];

export const useMoreConfigs = () => {
  return [
    {
      label: 'Nhu cầu',
      pathname: `${getRouteSearchList({
        typeOfDemand: 'ToBuy',
        realEstateType: allRealEstateType,
        postType: allPostType,
      })}?isForSell=ToBuy`,
    },
    {
      label: 'Tin tức',
      pathname: '/404',
    },
  ];
};

export const demandConfigs = [
  {
    label: 'Cần mua nhà',
    pathname: getRouteSearchList({
      typeOfDemand: 'ToBuy',
      realEstateType: allRealEstateType,
      postType: allPostType,
    }),
    isForSell: 'ToBuy',
  },
  {
    label: 'Cần thuê nhà',
    pathname: getRouteSearchList({
      typeOfDemand: 'ToRent',
      realEstateType: allRealEstateType,
      postType: allPostType,
    }),
    isForSell: 'ToRent',
  },
];

export const newsConfigs = [
  {
    label: 'Công ty cung cấp vật liệu xây dựng',
    pathname: '/search-list-materials',
  },
  {
    label: 'Công ty tư vấn luật',
    pathname: '/seek-legal-advice',
  },
  {
    label: 'Vàng',
    pathname: '/search-list-gold',
  },
  {
    label: 'Xe hơi',
    pathname: '/search-list-car',
  },
  {
    label: 'Ngân hàng',
    pathname: '/search-list-banks',
  },
  {
    label: 'Tin tức bất động sản',
    pathname: '/coming-soon',
  },
];

export const usePostSelections = (setOpenUpdateCompanyInfo) => {
  const { isLogin } = useAppContext();
  const { data } = useQuery(
    GET_USER_INFO_BY_FIELD(['type', 'statusProfileCompany']),
    { skip: !isLogin },
  );

  const isCompany = data?.user.type === 'Company',
    isApproved = data?.user.statusProfileCompany === 'approved';

  const fieldPostProject = isCompany
    ? [
        {
          label: 'Đăng tin dự án',
          pathname: isApproved ? '/404' : '#',
          onClick: () => {
            if (!isApproved) {
              setOpenUpdateCompanyInfo(true);
            }
          },
        },
      ]
    : [];

  return [
    {
      label: 'Đăng tin rao',
      pathname: '/dang-tin-rao',
    },
    {
      label: 'Đăng tin cần mua - cần thuê',
      pathname: '/dang-tin-can-mua-can-thue',
    },
    ...fieldPostProject,
  ];
};

import styles from '@styles/components/Header/Header.module.scss';
import LogoRTBlue from '@public/svg/logoRealTechMobile.svg';
import LogoRealTech from '@public/svg/logoRealTech.svg';
import Link from 'next/link';
import MenuSelection from '@components/header/components/MenuSelection';
import Notification from '@components/header/components/Notification';

import { useGetUserInfo } from '@hooks/publishRealEstate/useGetUserInfo';

import useOpenModalLogin from '@hooks/auth/login/useOpenModalLogin';
import { useOpenSignUp } from '@hooks/auth/login/useOpenSignUp';
import AuthenticationMobile from '@components/AuthenticationMobile';

import React from 'react';
import { Skeleton } from '@mui/material';
type Props = {
  wrapperClass?: string,
};

export default function Header({ wrapperClass }: Props) {
  const { userInfo, loading } = useGetUserInfo();

  const { showModal, setShowModal, gotoLogin } = useOpenModalLogin();

  const { openSignUp, setShowSignUp, showSignUp } = useOpenSignUp();

  return (
    <header style={{ zIndex: 1001 }} className="w-full">
      <div className={`${styles.headerContainer} ${wrapperClass}`}>
        <Link href="/">
          <a>
            <div className="flex lg:hidden">
              <LogoRTBlue width={92} height={30} />
            </div>
            <div className="hidden lg:flex">
              <LogoRealTech width={123} height={40} />
            </div>
          </a>
        </Link>
        <MenuSelection />
        {loading ? (
          <Skeleton variant="rectangular" width={200} height={49} />
        ) : userInfo?.user?.id ? (
          <div className="flex lg:hidden">
            <Notification />
          </div>
        ) : (
          <div className="flex lg:hidden w-full">
            <div className="w-full h-auto flex justify-end">
              <div className="w-86px h-28px text-center font-medium">
                <button className="font-bold" onClick={gotoLogin}>
                  Đăng nhập
                </button>
              </div>
              <div className="w-86px h-28px text-center rounded-rd31px text-blue-main bg-blue-bgRegister ml-2">
                <button className="font-bold" onClick={openSignUp}>
                  Đăng ký
                </button>
              </div>
              <AuthenticationMobile
                showLogin={showModal}
                setShowLogin={setShowModal}
                showSignUp={showSignUp}
                setShowSignUp={setShowSignUp}
              />
            </div>
          </div>
        )}
      </div>
    </header>
  );
}
